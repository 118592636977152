import React, {useState} from "react"
import logo from "../svg/logo.svg"
import Transition from "../components/Transition"

export default function NavBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={"relative p-6 " + props.bg}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-center">
          <div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
            <div className="flex items-center justify-between w-full lg:w-auto">
              <a href="/" aria-label="Home">
                <img className="w-48" src={logo} alt="Logo" />
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <button type="button" onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-yellow-300 " id="main-menu" aria-label="Main menu" aria-haspopup="true">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex md:space-x-10 font-body">
            <a href="/services" className="font-medium text-secondary hover:text-primary transition duration-150 ease-in-out">Services</a>
            <a href="/locations" className="font-medium text-secondary hover:text-primary transition duration-150 ease-in-out">Locations</a>
            <a href="/products" className="font-medium text-secondary hover:text-primary transition duration-150 ease-in-out">Products</a>
            <a href="/about" className="font-medium text-secondary hover:text-primary transition duration-150 ease-in-out">About</a>
            <a href="/contact" className="font-medium text-secondary hover:text-primary transition duration-150 ease-in-out">Contact</a>
          </div>
          <div className="hidden lg:absolute lg:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <span className="inline-flex rounded-md shadow">
              <a href="/contact" className="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-black bg-yellow-300 ">
                Free Quote
                </a>
            </span>
          </div>
        </nav>
      </div>

      {/* <!--
          Mobile menu, show/hide based on menu open state.
    
          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        --> */}
      <Transition
        show={isOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
      <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right z-10 lg:hidden">
        <div className="rounded-lg shadow-md">
          <div className="rounded-lg bg-primary shadow-xs overflow-hidden font-body" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-16 w-auto" src={logo} alt="Logo" />
              </div>
              <div className="-mr-2">
                <button type="button" onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-yellow-300" aria-label="Close menu">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              <a href="/services" className="block px-3 py-2 rounded-md text-base font-medium text-secondary hover:text-white" role="menuitem">Services</a>
              <a href="/locations" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-secondary hover:text-white" role="menuitem">Locations</a>
              <a href="/products" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-secondary hover:text-white" role="menuitem">Products</a>
              <a href="/about" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-secondary hover:text-white" role="menuitem">About</a>
              <a href="/contact" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-secondary hover:text-white" role="menuitem">Contact</a>
            </div>
            <div>
              <a href="/contact" className="block w-full px-5 py-3 text-center font-bold text-black bg-yellow-300" role="menuitem">
                Free Quote
                </a>
            </div>
          </div>
        </div>
      </div>
      </Transition>
    </div>

  )
}