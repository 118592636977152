import React from "react"
import { Helmet } from "react-helmet"

export default function Layout({children, props}) {
  return (
    <div className="bg-black" style={{maxWidth: '100%'}}>
      <Helmet>
          <title>East Coast Vending Services</title>
          <meta charSet="utf-8" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="East Coast Vending Services" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://jsjunkservices.com" />
          <meta property="og:site_name" content="East Coast Vending Services" />
          <link rel="canonical" href="" />
          {/* <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} /> */}
      </Helmet>
        {children}
    </div>
  )
}